.message {
  position: fixed;
  bottom: 28px;

  transform: translate(-50%, 0);
  margin: 0 auto;
  left: 50%;
  &.error {
    left: 45%;
    transform: translate(-40%, 0);
  }
  .closeIcon {
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    --leaf-icon-height: 24px;
    --leaf-icon-width: 16px;
  }
}
