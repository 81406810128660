.container {
  background-color: var(--leaf-color-bg-brand-strong);

  color: var(--leaf-color-content-knockout);

  .footerInner {
    padding: 2rem 0;
  }

  hr {
    border: 1px solid var(--leaf-color-border-brand);
    margin: 2rem 0;
  }

  .legalText {
    font-size: var(--leaf-typography-body-small-font-size);
  }

  .legalLinks {
    display: flex;
    padding-bottom: 1rem;

    a,
    a:active,
    a:hover {
      color: var(--leaf-color-content-knockout);
      padding: 0 0.5rem;
    }

    a:first-child {
      padding-left: 0;
    }
  }
}
