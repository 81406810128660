.panelFooter {
  display: flex;
  gap: 1rem;
  padding: 1rem 0 0 0;
  margin-top: 1rem;

  leaf-button {
    flex-grow: 1;
  }
}

.dateInputs {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}
