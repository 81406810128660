@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.container {
  position: relative;

  .suggestionsPanel {
    display: block;
    padding: 0.5rem;
    border-width: var(--leaf-border-width-default);
    border-style: solid;
    border-color: var(--leaf-color-border-strong);
    border-radius: var(--leaf-border-radius-default);

    position: absolute;
    z-index: 1;
    top: 80px;

    background-color: var(--leaf-color-bg-default);
    box-shadow: 0 8px 6px 0 rgb(0 0 0 / 0.1);

    width: 100%;

    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;

    .item {
      @include typography.leaf-typography-body-small();

      border-radius: var(--leaf-border-radius-default);
      padding: 0.5rem;
      width: 100%;
      border: none;
      background-color: transparent;
      text-align: left;

      &:hover {
        background-color: var(--leaf-color-bg-brand-hover);
        cursor: pointer;
      }

      &:focus-visible {
        outline: var(--leaf-border-width-focus) solid
          var(--leaf-color-border-active-outline);
      }
    }
  }
}
