/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.errorContainer {
  padding-top: 1.5rem;
}
