@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.cardListItem {
  border-bottom-color: var(--leaf-color-border-strong);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  display: flex;
  align-items: center;
  padding: 24px;

  &:hover {
    cursor: pointer;
  }
}

.cardListItemLast {
  border-bottom-width: 0;
}

.iconLeftWrapper {
  margin-right: 24px;
}

.iconLeft {
  --leaf-icon-height: 2rem;
  --leaf-icon-width: 2rem;
  color: var(--leaf-color-content-brand-strong);
}

.iconRightWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: 24px;
}

.textWrapper {
  flex: 1;
}

.headline {
  @include typography.leaf-typography-title-default;
}

.description {
  @include typography.leaf-typography-body-large;
  color: var(--leaf-color-content-subtle);
  padding-top: 8px;
}

.childContent {
  padding-top: 8px;
  width: 100%;
}
