.container {
  .showMoreButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    appearance: none;
    background-color: var(--leaf-color-bg-transparent);
    border: var(--leaf-border-width-none);
  }
  .showMoreButton leaf-icon-legacy {
    --leaf-icon-height: 24px;
    --leaf-icon-width: 24px;
    margin-left: 0.3rem;
  }
}
