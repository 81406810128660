@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.paginationContainer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0 8px 16px;
  align-items: center;

  .pageCounts {
    @include typography.leaf-typography-label-small;
  }
}
