.linkContainer {
  width: 100%;
  background: var(--leaf-color-bg-default);
  border-color: var(--leaf-color-border-strong);
  border-radius: var(--leaf-border-radius-large);
  border-style: solid;
  border-width: 1px;
  margin-bottom: 3rem;
}

.editContainer {
  width: 100%;
  margin-bottom: 3rem;
}

.heading {
  margin-bottom: 2rem;
}
