@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.navItemWrap {
  display: inline-flex;
  position: relative;
}

a.navLink {
  padding: 1.25rem 1rem;
  margin: 0;

  display: inline-flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  color: var(--leaf-color-content-knockout);
  background-color: transparent;
  border-bottom-color: transparent;
  border-bottom-width: 4px;
  border-bottom-style: solid;

  z-index: 1000;

  @include typography.leaf-typography-label-default;

  &:hover {
    color: var(--leaf-color-content-knockout);
    background-color: #00363d; // menu-inverse-bg-hover
  }

  &:active {
    color: var(--leaf-color-content-knockout);
    background-color: #00171a; // menu-inverse-bg-active
  }

  &:visited {
    color: var(--leaf-color-content-knockout);
  }
}

a.navLinkActive {
  background-color: #00363d; // menu-inverse-bg-hover
  border-bottom-color: var(--leaf-color-border-knockout);
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

.navMenu {
  background-color: var(--leaf-color-bg-brand-strong);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 175px;

  li {
    a {
      padding: 8px 16px;
      @include typography.leaf-typography-label-small;
      display: block;
      color: var(--leaf-color-content-knockout);
    }

    &:hover {
      background-color: #00363d; // menu-inverse-bg-hover
    }

    &:active {
      background-color: #00171a; // menu-inverse-bg-active
    }
  }
}

a.subNavLinkActive {
  background-color: #00363d; // menu-inverse-bg-hover
  // border-bottom-color: var(--leaf-color-border-knockout);
  // border-bottom-width: 4px;
  // border-bottom-style: solid;
}

// .navMenuVisible {
// }
