@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.fileUploadSection {
  .fileUploadContainer {
    margin-top: 2rem;

    .fileUpload {
      --leaf-border-style-default: dashed;
      --leaf-color-border-default: var(--leaf-color-border-strong);
      --leaf-border-width-default: 2px;
      --leaf-border-radius-default: 16px;
      --leaf-color-bg-default: var(--leaf-color-bg-brand);
      position: relative;
      &.error {
        --leaf-border-style-default: solid;
        --leaf-color-border-default: var(--leaf-color-border-error);
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;

      leaf-icon-legacy {
        --leaf-icon-height: 32px;
        --leaf-icon-width: 32px;
        color: var(--leaf-color-content-brand-strong);
      }
    }

    .browseBtn {
      @include typography.leaf-typography-body-default-link;
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
      color: var(--leaf-color-content-link);
      &:hover {
        color: var(--leaf-color-content-link-hover);
        text-decoration: underline;
      }
    }
  }

  .draggingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--leaf-color-bg-brand);
    border-radius: 16px;
    border-style: dashed;
    border-width: 3px;
    border-color: var(--leaf-color-border-brand);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .errorMsg {
    padding-top: 1rem;
  }
}
