.container {
  background-color: var(--leaf-color-bg-default);
  z-index: 100;
  box-shadow: 0 8px 6px 0 #0000001a;
}

.headerInner {
  height: 84px;
  display: flex;
  align-items: center;
}

.logoLink {
  margin-right: 3rem;
}

.accountLink {
  margin-left: auto;
}
