@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.saveCardContainer {
  border-color: var(--leaf-color-border-strong);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--leaf-border-radius-large);
  background: var(--leaf-color-bg-default);
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;

  .childContainer {
    padding-bottom: 24px;
  }

  .bottomRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
  }

  .saveBtnRow {
    display: flex;
    padding: 12px 0;
    leaf-button {
      margin: auto;
    }
  }
  .saveBtnRow div {
    flex: 1;
    display: flex;
    align-self: center;
  }

  .saveBtn {
    border-right: 1px solid var(--leaf-color-border-default);
  }
}
