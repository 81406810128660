.noResultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 8px;

    .subText {
      font-size: 24px;
      max-width: 1024px;
      text-align: center;

      a {
        font-size: 24px;
      }
    }
  }
}
.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  gap: 24px;
  .fileIcon {
    --leaf-icon-height: 2rem;
    --leaf-icon-width: 2rem;
    color: var(--leaf-color-content-brand);
  }
  .subText {
    font-size: 16px;
  }
}
.emptyState {
  border: var(--leaf-border-width-default) solid var(--leaf-color-border-strong);
  border-radius: var(--leaf-border-radius-default);
}
