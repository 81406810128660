@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.editLinkCard {
  border-color: var(--leaf-color-border-strong);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--leaf-border-radius-large);
  background: var(--leaf-color-bg-default);
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
}

.desscription {
  @include typography.leaf-typography-body-large;
  color: var(--leaf-color-content-subtle);
}

.statusBadge {
  margin-left: auto;
}

.topRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
}

.bottomRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
}

.childContainer {
  padding-bottom: 24px;
}
